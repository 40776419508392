import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { menuItems, socialItems } from "../../constants/links"
import { FooterStyles } from "./FooterStyles"

const Footer = () => {
  return (
    <FooterStyles>
      <div className="container">
        <div className="footer__menu">
          <ul>
            {menuItems.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={item.path} activeClassName="menu__item--active">
                    {item.text}
                    <span>.</span>
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="footer__items">
          <div className="item">
            <StaticImage src="../../images/footer/memento2019.png" />
          </div>
          <div className="item">
            <StaticImage src="../../images/footer/memento2020.png" />
          </div>
          <div className="item">
            <a
              href="https://www.weselezklasa.pl/zespolmemento/"
              target="_blank"
              title="Zespół Muzyczny MEMENTO | KOMPLEKSOWA ORGANIZACJA WESEL / EVENTÓW /DJ"
            >
              <img
                src="https://www.weselezklasa.pl/banery/Weselezklasa/logo128x128przezroczystetlobordowewypelnienieborderzaokraglony.png"
                alt="Zespół Muzyczny MEMENTO | KOMPLEKSOWA ORGANIZACJA WESEL / EVENTÓW /DJ"
                border="0"
              />
            </a>
          </div>
        </div>
        <div className="social__menu">
          <ul>
            {socialItems.map((item, index) => {
              return (
                <li key={index}>
                  <a
                    href={item.path}
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                  >
                    {item.icon}
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
        <div className="copyright">
          <p>
            Memento Group {new Date().getFullYear()}
            <span>.</span>
          </p>
        </div>
      </div>
    </FooterStyles>
  )
}

export default Footer
