import * as React from "react"
import {
  FaFacebookSquare as Facebook,
  FaInstagram as Instagram,
  FaYoutube as Youtube,
  FaTiktok as TikTok
} from "react-icons/fa"

export const menuItems = [
  {
    path: "/",
    text: "Główna Strona",
  },
  {
    path: "/about",
    text: "O Nas",
  },
  {
    path: "/offer",
    text: "Oferta",
  },
  {
    path: "/band",
    text: "Zespół",
  },
  {
    path: "/dj",
    text: "DJ"
  },
  {
    path: "/organisation",
    text: "Organizacja",
  },
  {
    path: "/faq",
    text: "F.A.Q",
  },
  {
    path: "/gallery",
    text: "Galeria",
  },
  {
    path: "/contact",
    text: "Kontakt"
  }
]

export const socialItems = [
  {
    path: "https://www.facebook.com/zespolmemento",
    icon: <Facebook />,
  },
  {
    path: "https://www.instagram.com/zespolmemento/",
    icon: <Instagram />,
  },
  {
    path: "https://www.youtube.com/channel/UC2a9zWs5hKRqeegQqVOlnVw/featured",
    icon: <Youtube />,
  },
  {
    path: "https://www.tiktok.com/@mementogroup?fbclid=IwAR2SfjnIhrOvvi4ytB-2nNGgdxKc2spmiXiP1t7wlPR9MQBbNAMK0Uw84Ig",
    icon: <TikTok />,
  },
]
